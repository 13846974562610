import { request as axios } from '@/util/request';
import { formatDate } from '@/util/global'
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');

interface QuerySendData {
    name?: string;
    receiver?: string;
    receiver_mobile?: number;
    page?: number;
    status: number;
}

interface AddSendData {
    u8_code?: string;
    name?: string;
    spec?: string;
    storage_condition?: number;
    guarantee_period?: number;
    carton_size?: string;
}

const API = {
    list: '/boss/pd',
    status: '/boss/pd/status',

};

function formatWare(remote: any = {}) {
    return {
        current: remote.current || 1,
        limit: remote.limit || 10,
        detail: remote.detail || [],
        total: remote.total || 0,
        last: remote.last || 1,
    };
}

export function formatWareData(remote: any = {}) {
    return {
        ...remote,
        status: remote.status == 1 ? true : false,
        create_time: formatDate(remote.create_time),
    };
}

/**
 * 查询产品数据
 * @param current 当前分页
 */
async function queryProduct(current: number = 1, send_data?: QuerySendData, limit?: number | undefined) {
    clean(send_data);
    const _send_data = {
        ...send_data,
        limit:10,
        // limit:2,
        current: current || undefined,
    };
    const url = getUrl(API.list, _send_data);
    const res = await axios.get(url);
    if (res.status !== 200) {
        //message.error((res as any).message);
    }
    const list = formatWare(res.data);
    list.detail = list.detail.map((item: any) => ({
        ...item,
        status: item.status == 1 ? true : false,

        create_time: formatDate(item.create_time),

    }));
    return list;
}

/**
 * 产品状态
 * @param id 产品 id
 * @param status
 */
async function statusProduct(status: number, id: number) {
    return await axios.put(`${API.status}/${id}`, { status: status });
}

/**
 * 产品详情
 * @param id 产品 id
 */
async function detailProduct(id: number) {
    const url = getUrl(`${API.list}/${id}`);
    const res = await axios.get(url);
    if (res.status !== 200) {
        //message.error((res as any).message);
    }
    return res
}

/**
 * 产品添加
 * @param id 产品 id
 */
async function addProduct(send_data: AddSendData) {
    clean(send_data);
    return await axios.post(API.list, send_data);
}

/**
 * 产品编辑
 * @param id 产品 id
 */
async function editProduct(send_data: AddSendData, id: number) {
    clean(send_data);
    return await axios.put(`${API.list}/${id}`, send_data);
}

/**
 * 产品删除
 * @param id 产品 id
 */
async function delProduct(id: number) {
    return await axios.delete(`${API.list}/${id}`);
}

export {
    queryProduct,
    addProduct,
    editProduct,
    detailProduct,
    statusProduct,
    delProduct,
};
