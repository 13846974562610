
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { useMain } from '@/pinia/index'
import { Form as aForm } from 'ant-design-vue';

// api
import { queryProduct, statusProduct, delProduct } from '@/api/factory/product';

// 组件
import CustomModal from '@/component/custom-modal.vue';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
const SEARCH_TYPE = [
  {
    id: 0,
    value: 'U8编码',
  },
  {
    id: 1,
    value: '产品编号',
  },
];
const STATUS_TYPE = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '启用',
  },
  {
    id: 2,
    value: '禁用'
  }
];

@Component({
  name:'FactoryProduct',
  components: {
    CustomModal,
  },
})
export default class FactoryProduct extends Vue {
  setGlobalFunction=useGlobalFunction().setGlobalFunction;
  searchForm: any
  pagination = useMain().pagination
  is_loading: boolean = false;
  next_page: number = 1;
  type: string = '';
  method: any = null;
  //搜索条件映射
  get searchType() {
    return SEARCH_TYPE;
  }
  // 状态映射
  get statusType() {
    return STATUS_TYPE
  }
  product_data: any = {
    current: 1
  }
  // 搜索
  seach: any = {
    search_type: 0,
    search_value: '',
    u8_code: '',
    id: '',
    name: '',
    status: 0,
    spec: ''
  }
  // 添加产品弹窗
  add_Modal: any = {
    title: '',
    visible: false,
    is_loading: false,
  };
  // 确认修改状态弹窗
  status_modal: any = {
    title: '',
    visible: false,
    content: [],
    is_loading: false,
  }
  // 失败弹窗
  fail_modal: any = {
    title: '抱歉，因以下原因状态修改失败',
    visible: false,
    content: [],
  };
  @changeLoading(['is_loading'])
  async handleSubmit(e) {
    e.preventDefault();
    this.searchForm.validateFields(async (err, values) => {
      if (!err) {
        this.seach = {
          ...this.seach,
          ...values,
        }
        await this.fetchProductList();
      }
    });
    return false

  }
  onTableChange(pagination: any) {
    this.next_page = pagination.current;
    this.fetchProductList(this.next_page);
  }
  //分页
  paginationOption(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }

  // 获取产品列表
  async fetchList(current: number = 1) {
    this.fetchProductList(current)
  }

  @changeLoading(['is_loading'])
  async fetchProductList(current: number = 1) {
    this.product_data = await queryProduct(current, this.getSendData());
  }
  getSendData() {
    const send_data: any = {
      u8_code: this.seach.search_type === 0 ? this.seach.search_value : '',
      id: this.seach.search_type === 1 ? this.seach.search_value : '',
      name: this.seach.name,
      status: this.seach.status == 0 ? '' : this.seach.status,
      spec: this.seach.spec,
    };
    for (const key in send_data) {
      if (send_data[key] === '') delete send_data[key];
    }
    return send_data
  }

  // 搜索清空重置
  async resetFilter() {
    this.seach = {
      search_type: 0,
      search_value: '',
      u8_code: '',
      id: '',
      name: '',
      status: 0,
      spec: ''
    };
    this.searchForm.resetFields()
    await this.fetchList();
  }

  // 状态/删除
  updateStatus(record: any, type: string) {
    if (type == 'status') {
      this.status_modal.title = '产品状态'
      this.status_modal.content = ['确认要修改此产品状态吗？']
      this.status_modal.visible = true;
      this.status_modal.data = record;
      this.type = type;
      this.method = this.status;
    } else {
      this.status_modal.title = '删除产品'
      this.status_modal.content = ['确认要删除此产品信息吗？']
      this.status_modal.visible = true;
      this.status_modal.data = record;
      this.type = type;
      this.method = this.del;
    }
  }

  cancelModal() {
    this.status_modal.visible = false
    if (this.type == 'status') {
      this.fetchProductList(this.next_page);
    }
  }

  // 删除提交
  @changeLoading(['is_loading'])
  async del() {
    this.status_modal.is_loading = true;
    const res: any = await delProduct(this.status_modal.data.id);
    this.status_modal.is_loading = false;
    this.status_modal.visible = false;
    if (res.status !== 200) {
      this.fail_modal.content = [...(res as any).message.split('\n')];
      this.fail_modal.visible = true;
      return;
    }
    await this.fetchList(this.product_data.current);
    this.$message.success('删除成功');
  }

  // 状态提交
  @changeLoading(['is_loading'])
  async status() {
    this.status_modal.is_loading = true;
    const res: any = await statusProduct(this.status_modal.data.status == true ? 1 : 2, this.status_modal.data.id);
    this.status_modal.is_loading = false;
    this.status_modal.visible = false;
    if (res.status !== 200) {
      this.fail_modal.content = [...(res as any).message.split('\n')];
      this.fail_modal.visible = true;
      return;
    }
    await this.fetchList(this.product_data.current)
    this.$message.success('状态修改成功');
  }
  oldSearchData(path: string) {
    this.pagination.product = {
      ...this.seach,
      current: this.product_data.current
    }
    this.$router.push(path)
  }

  @changeLoading(['is_loading'])
  async created() {
    this.searchForm = (aForm as any)?.createForm(this, {
      name: 'form',
    });
    if (this.pagination?.product) {
      Object.keys(this.pagination.product).forEach((item) => {
        if (item !== 'current') {
          this.seach[item] = this.pagination.product[item]
        }
      })
      await this.fetchList(this.pagination.product.current);
      delete this.pagination.product
      return
    }
    this.fetchList(this.$route.query.page ? +this.$route.query.page : 1);
    this.setGlobalFunction({productManagementList:this.fetchList})
  }

}
